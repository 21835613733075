.load {
  background: white;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 100000000;
  opacity: 0.9;
  border-radius: 6px;
}

.load div {
  z-index: 30000000;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
